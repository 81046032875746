import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { api, getAddressOfOrder, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";

import Reducer from "../utilities/Reducer";
import "../css/style.css";

const Step20_Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [order, setOrder] = useState({});
    const [buyerInfo, setBuyerInfo] = useState({});

    const [hasAgreement, setHasAgreement] = useState(false);

    const [promotionCode, setPromotionCode] = useState("");
    const [addedPromotionCode, setAddedPromotionCode] = useState("");
    const [promotionError, setPromotionError] = useState("");

    const [tickets, setTickets] = useState([]);
    const [payment, setPayment] = useState({
        paymentMethodId: 0,
        deliveryMethodId: 0,
        insurance: false
    })

    let navigate = useNavigate();

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }

        scrollToTop();
    }, [orderId]); //gets called when an order is started

    const scrollToTop = () => {
        var scrollElement = document.getElementById("datatrans-form-placeholder");

        if (scrollElement) {
            scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);
            const address = await getAddressOfOrder(orderId);

            if (updatedOrder.paymentMethodId == 2) {
                changePaymentType(1)
            }

            const bookedTickets = updatedOrder?.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    let birthDate = new Date(ticket.birthDate);
                    ticket.birthDate = birthDate.toLocaleDateString()
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            let updatedPayment = { ...payment, deliveryMethodId: updatedOrder?.deliveryMethodId, paymentMethodId: updatedOrder?.paymentMethodId };

            updatedPayment.insurance = updatedOrder?.totalInsuranceAmount > 0;

            setPayment(updatedPayment);
            setOrder(updatedOrder);
            setTickets(updatedTickets);
            setBuyerInfo({ ...updatedTickets[0] });
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changeDeliveryType = async (deliveryMethodId) => {
        await axios.put(
            `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryMethodId}`)
            .then(() => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const deleteTicketsFromBasket = async (ticketIds) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: { "TicketsToRemove": ticketIds } });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCode = async () => {
        await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionCode}`)
            .then((res) => {
                setPromotionError("");
                setAddedPromotionCode(promotionCode);
                loadOrder(orderId);
            }).catch((error) => {
                setPromotionError("Dieser Gutschein Code kann nicht auf ihre aktuelle Bestellung angewendet werden.");
            })
    }

    const removePromotionCode = async (promotion) => {
        await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`)
            .then((res) => { }).catch((error) => { })
    }

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const onSubmit = async () => {
        if (order?.totalPrice > 0) {
            let dataTransProviders = [1, 7, 8, 9, 10, 14];

            let datatransProvider = dataTransProviders.findIndex((d) => d === payment.paymentMethodId) !== -1

            if (datatransProvider) {
                startDatatrans();
            }
        } else {
            changePaymentTypeToFree();
        }
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    }

    const mapSelectedTickets = () => {
        return (
            <div>
                {tickets?.map((ticket, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-10 fs-6">
                            {ticket.info?.name}
                        </div>
                        <div className="col-md-2 text-end">
                            {ticket.price + " " + ticket.currency}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const mapExtraCosts = () => {
        return (
            <div>
                <p
                    className={
                        "d-flex justify-content-between align-items-start mb-2 fs-6"
                    }
                >
                    {resources.checkOut.TotalCosts}
                    <span></span>
                    {order?.deliverPaymentFee.toFixed(2) + " " + order?.currency}
                </p>
            </div>
        );
    };

    const totalSelectedTickets = () => {
        let total = order?.totalPrice;
        total = Math.round(total * 100) / 100;

        return (
            <div>
                <p className={"d-flex justify-content-between align-items-start mb-2"}>
                    <p className="fw-bold fs-6">Total</p>
                    <span></span>
                    <p className="fw-bold fs-6">
                        {order && (total.toFixed(2) + " " + order?.currency)}
                    </p>
                </p>
            </div>
        );
    };

    return (
        <div>
            <div id="datatrans-form-placeholder"></div>

            {resources.translation && (
                <div className="container pt-0 wrapper">
                    <div className="mt-5">
                        <div className="row title-box">
                            <h4>{resources.checkOut.Delivery}</h4>
                        </div>
                        <div className="mb-4 mt-4 check-page-box">
                            <div className="mb-3">
                                <input
                                    className="me-3"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    checked={payment.deliveryMethodId === 1 ? true : false}
                                    onClick={() => changeDeliveryType(1)}
                                    id="printAtHome"
                                />
                                <label
                                    className="form-check-label fs-6 bold"
                                    htmlFor="printAtHome"
                                >
                                    {resources.checkOut.DeliveryPrintAtHome}
                                </label>
                                <p className="pt-1 m-0">
                                    <small className="margin-fees">{resources.checkOut.DeliveryNoFee}</small>
                                </p>
                            </div>
                        </div>
                        {order?.totalPrice > 0 && <>
                            <div className="row title-box">
                                <h4>{resources.checkOut.Payment}</h4>
                            </div>
                            <div className="mb-4 mt-4 check-page-box">
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentMethodId === 1 ? true : false}
                                        onClick={() => changePaymentType(1)}
                                        id="mastercard"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="mastercard"
                                    >
                                        Mastercard
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentMethodId === 7 ? true : false}
                                        onClick={() => changePaymentType(7)}
                                        id="visa"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="visa"
                                    >
                                        Visa
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentMethodId === 8 ? true : false}
                                        onClick={() => changePaymentType(8)}
                                        id="amex"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="amex"
                                    >
                                        American Express
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentMethodId === 14 ? true : false}
                                        onClick={() => changePaymentType(14)}
                                        id="twint"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="twint"
                                    >
                                        Twint
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>

                                <div className="mb-3 mt-4">
                                    <input
                                        className="me-3"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={payment.paymentMethodId === 16 ? true : false}
                                        onClick={() => changePaymentType(16)}
                                        id="googlePay"
                                    />
                                    <label
                                        className="form-check-label fs-6 bold"
                                        htmlFor="googlePay"
                                    >
                                        GooglePay
                                    </label>
                                    <p className="pt-1 m-0">
                                        <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                    </p>
                                </div>
                                {window.ApplePaySession &&
                                    <div className="mb-3 mt-4">
                                        <input
                                            className="me-3"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 17 ? true : false}
                                            onClick={() => changePaymentType(17)}
                                            id="applePay"
                                        />
                                        <label
                                            className="form-check-label fs-6 bold"
                                            htmlFor="applePay"
                                        >
                                            ApplePay
                                        </label>
                                        <p className="pt-1 m-0">
                                            <small className="margin-fees">{resources.checkOut.PaymentNoFees}</small>
                                        </p>
                                    </div>
                                }
                            </div>
                        </>}
                        <div className="row title-box">
                            <h4>Gutschein Code</h4>
                        </div>
                        <div className="mb-4 mt-4 check-page-box">
                            <p>Falls Sie einen Gutschein Code zur Preisreduktion besitzen, können Sie diesen hier eingeben. Bei mehreren Codes, die Codes einzeln hinzufügen.</p>
                            <div className="row">
                                <div className="col-md-8 col-12 ps-2 pb-2">
                                    <input
                                        id="inputCode"
                                        type="text"
                                        name="promotionCode"
                                        className="form-control custom-textbox"
                                        placeholder={"Gutschein Code"}
                                        value={promotionCode}
                                        onChange={(e) => setPromotionCode(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4 col-6 ps-2 text-center">
                                    <button
                                        className="custom-button col-12"
                                        onClick={() => applyPromotionCode()}
                                        disabled={promotionCode === ""}
                                    >
                                        Hinzufügen
                                    </button>
                                </div>
                            </div>
                            {promotionError != "" && <p className="text text-danger">{promotionError}</p>}
                        </div>
                        <div className="row mb-4 mt-4 title-box">
                            <h4>{resources.checkOut.Summary}</h4>
                        </div>
                        <div>
                            {mapSelectedTickets()}
                            {order?.deliverPaymentFee > 0 && mapExtraCosts()}
                            <hr></hr>
                            {totalSelectedTickets()}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            {buyerInfo &&
                                <div>
                                    <span>{buyerInfo.salutation} {buyerInfo.firstname} {buyerInfo.lastname}</span> <br />
                                    <span>{buyerInfo.street}</span> <br />
                                    <span>{buyerInfo.postCode} {buyerInfo.city}</span> <br /><br />
                                    <span>Führerausweis Nr.: {buyerInfo.customValue2}</span> <br />
                                    <span>Geburtsdatum: {buyerInfo.birthDate}</span> <br />
                                    <span>Telefon: {buyerInfo.mobile}</span> <br />
                                    <span>E-Mail: {buyerInfo.email}</span> <br />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12 float-end">
                            <input id="chkAgreement" type="checkbox" className="me-2" value="agreement" onChange={() => setHasAgreement(!hasAgreement)} />
                            <label htmlFor="chkAgreement" dangerouslySetInnerHTML={{ __html: resources.checkOut.Agreement }}></label>
                        </div>
                    </div>

                    {/* next button */}
                    <div className={"row mt-5 mb-5"}>
                        <div className="col-md-4 text-end">
                            <button
                                className="form-control btn-custom"
                                onClick={() => {
                                    if (addedPromotionCode != "") {
                                        removePromotionCode(addedPromotionCode);                                        
                                    }
                                    
                                    navigate(`/${language}/home`)
                                }}
                            >
                                {resources.checkOut.Back}
                            </button>
                        </div>
                        <div className="offset-md-4 col-md-4 text-end">
                            <button
                                className="form-control custom-button"
                                onClick={onSubmit}
                                disabled={
                                    payment.paymentMethodId === 0 && payment.deliveryMethodId === 0 || !hasAgreement
                                }
                            >
                                {resources.checkOut.FinishOrder}
                            </button>
                        </div>
                    </div>

                    <p className="mt-5 text-center">
                        {resources.translation && (
                            <small style={{ color: '#ffa834' }}>powered by <a style={{ color: '#ffa834', textDecoration: 'none' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};

export default Step20_Checkout;
