import axios from "axios";
import React, { useEffect, useState } from "react";
import imageLogo from "../../img/logo.jpg";

const Header = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className="row mt-4">
        <div className="offset-3 col-6 offset-md-4 col-md-4">
            <a href="https://www.toefftesttage.ch/"><img src={imageLogo} className="img-fluid" alt="t�ff messe"></img></a>
        </div>
        </div>
    );
};

export default Header;
